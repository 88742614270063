import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useApiRequest from '../hooks/useApiRequest'

const ShipmentHistory = ({ shipmentsPath, showDownloadLabel, t }) => {
  const [shipments, setShipments] = useState([])
  const { isLoading, sendRequest } = useApiRequest()

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('historyId')
    }

    const handleLoad = () => {
      loadJSON()
    }

    const handleHashChange = () => {
      if (window.location.hash === '#program-activity') {
        loadJSON()
      } else {
        localStorage.removeItem('historyId')
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    window.addEventListener('load', handleLoad)
    window.addEventListener('hashchange', handleHashChange)

    // Cleanup listeners on unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      window.removeEventListener('load', handleLoad)
      window.removeEventListener('hashchange', handleHashChange)
    }
  }, [])

  const removeFilter = () => {
    localStorage.removeItem('historyId')
    loadJSON()
  }

  const loadJSON = async () => {
    const url = localStorage.getItem('historyId') || shipmentsPath
    try {
      const data = await sendRequest(url)
      setShipments(data.shipments)
    } catch (error) {
      window.alert(t['tcnext.failed_to_fetch_shipment_history'])
    }
  }

  const renderShipments = (shipments) => (
    <div>
      <div className="tableComponent tableComponent_oneColumnMobile">
        <div
          className="custom-scroll"
          data-simplebar
          data-simplebar-auto-hide="false"
        >
          <table className="tableComponent_table">
            <thead>
              <tr className="tableComponent_mobileHead">
                <th>{t['tcred.profile.shipments_history.shipments_title']}</th>
              </tr>
              <tr className="tableComponent_desktopHead">
                <th>{t['tcred.profile.shipments_history.shipment_date']}</th>
                <th className="shipmentHistory_program">
                  {t['tcred.profile.shipments_history.program']}
                </th>
                <th className="d-flex align-items-center">
                  {t['tcred.profile.shipments_history.status']}
                  <button
                    className="tooltip-btn icon-tooltip ms-2 text-white"
                    data-bs-placement="top"
                    data-bs-toggle="tooltip"
                    title={t['tcred.profile.shipments_history.status.tooltip']}
                    type="button"
                  />
                </th>
                <th>{t['tcred.profile.shipments_history.shipment_weight']}</th>
              </tr>
            </thead>
            <tbody>
              {shipments.map((shipment) =>
                shipment.status === t['shipment.status.received']
                  ? renderReceived(shipment)
                  : renderOutstanding(shipment),
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )

  const renderOutstanding = (shipment) => (
    <tr key={shipment.uuid}>
      <td className="d-flex d-md-table-cell">
        <div className="col-5 d-md-none">
          <b>{t['tcred.profile.shipments_history.shipment_date']}:</b>
        </div>
        <div>
          <div>{shipment.requested_at}</div>
          <div className="d-none d-md-block">
            {shipment.label_available && showDownloadLabel ? (
              renderDownloadLabelButton(shipment.label_url)
            ) : (
              <div className="grey-text smallText">
                {t['tcred.profile.shipments_history.expired']}
              </div>
            )}
          </div>
        </div>
      </td>
      <td className="d-flex d-md-table-cell mb-2">
        <div className="col-5 d-md-none">
          <b>{t['tcred.profile.shipments_history.program']}:</b>
        </div>
        <div>{shipment.brigade_name}</div>
      </td>
      <td className="d-flex d-md-table-cell mb-2">
        <div className="col-5 d-md-none">
          <b>{t['tcred.profile.shipments_history.status']}:</b>
        </div>
        <div>{shipment.status}&nbsp;</div>
      </td>
      <td className="d-flex d-md-table-cell smallText">
        <div className="col-5 d-md-none">
          <b>{t['tcred.profile.shipments_history.shipment_details']}:</b>
        </div>
        <div>
          <div>{shipment.tracking_number}</div>
          <div className="grey-text">
            {t['tcred.profile.shipments_history.not_yet_received']}
          </div>
        </div>
      </td>
      {shipment.label_available && showDownloadLabel ? (
        <td className="text-center d-md-none mt-5">
          {renderDownloadLabelButton(shipment.label_url)}
        </td>
      ) : (
        <td className="grey-text text-center smallText d-md-none mt-5">
          {t['tcred.profile.shipments_history.expired']}
        </td>
      )}
    </tr>
  )

  const renderReceived = (shipment) => (
    <tr key={shipment.uuid}>
      <td className="d-flex d-md-table-cell">
        <div className="col-5 d-md-none">
          <b>{t['tcred.profile.shipments_history.shipment_date']}:</b>
        </div>
        <div>{shipment.requested_at}</div>
      </td>
      <td className="d-flex d-md-table-cell mb-2">
        <div className="col-5 d-md-none">
          <b>{t['tcred.profile.shipments_history.program']}:</b>
        </div>
        {shipment.brigade_name}
      </td>
      <td className="d-flex d-md-table-cell mb-2">
        <div className="col-5 d-md-none">
          <b>{t['tcred.profile.shipments_history.status']}:</b>
        </div>
        <div>
          <div>{shipment.status}&nbsp;</div>
          <div>{shipment.received_at}</div>
        </div>
      </td>
      <td className="d-flex d-md-table-cell">
        <div className="col-5 d-md-none">
          <b>{t['tcred.profile.shipments_history.shipment_details']}:</b>
        </div>
        <div>
          <div>
            <div>{shipment.tracking_number}</div>
            <span className="d-md-none">{shipment.unpackaged_weight}</span>
            <span className="d-none d-md-block">
              {shipment.unpackaged_weight}
            </span>
          </div>
          <div>
            <span className="d-md-none">{shipment.points_earned}</span>
            <span className="d-none d-md-block">{shipment.points_earned}</span>
          </div>
        </div>
      </td>
    </tr>
  )

  const renderDownloadLabelButton = (labelUrl) => (
    <a
      className="shipmentHistory_requestLabel readMoreLink"
      href={labelUrl}
      rel="noopener noreferrer"
      target="_blank"
    >
      {t['tcnext.download_label']}
    </a>
  )

  return (
    <div>
      {localStorage.getItem('historyId') && (
        <div className="mb-5">
          <button
            className="buttonComponent--primary buttonComponent--small"
            onClick={removeFilter}
          >
            {t['tcred.profile.my_programs.show_all_programs']}
          </button>
        </div>
      )}
      {isLoading ? (
        `${t['tcnext.loading']}...`
      ) : shipments.length > 0 ? (
        renderShipments(shipments)
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: t['tcred.profile.my_programs.no_program_activity_html'],
          }}
        />
      )}
    </div>
  )
}

ShipmentHistory.propTypes = {
  shipmentsPath: PropTypes.string.isRequired,
  showDownloadLabel: PropTypes.bool,
  t: PropTypes.object.isRequired,
}

export default ShipmentHistory

import React from 'react'
import PropTypes from 'prop-types'

const PointsSection = ({
  commonPointsAllocation,
  pointsPerShipment,
  shipmentMinWeightUnits,
  shipmentMinWeightValue,
  t,
}) => {
  const {
    'tcred.account.card.reward_per_shipment': rewardPerShipment,
    'tcred.account.card.minimum_weight': minimumWeight,
    'tcred.account.card.no_minimum_weight_tool_tip': noMinWeightTooltip,
    'tcred.account.card.minimum_weight_tool_tip': minWeightTooltip,
  } = t

  const weightValue = parseFloat(shipmentMinWeightValue ?? 0)

  return (
    <div className="row g-0">
      {pointsPerShipment && !commonPointsAllocation && (
        <div className="col-lg-6">
          <div className="activeCollection_points-box">
            <div className="activeCollection_points-box-label">
              {rewardPerShipment}
            </div>
            <div className="activeCollection_points-box-value">
              {pointsPerShipment}
            </div>
          </div>
        </div>
      )}

      {shipmentMinWeightValue != null && (
        <div className="col-lg-6">
          <div className="activeCollection_points-box">
            <div className="activeCollection_points-box-label">
              {minimumWeight}
            </div>
            <div className="activeCollection_points-box-value">
              {weightValue !== 0 ? shipmentMinWeightValue : ''}
              &nbsp;{shipmentMinWeightUnits}&nbsp;
              <button
                className="tooltip-btn icon-tooltip"
                data-bs-toggle="tooltip"
                title={
                  weightValue === 0 ? noMinWeightTooltip : minWeightTooltip
                }
                type="button"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

PointsSection.propTypes = {
  commonPointsAllocation: PropTypes.bool,
  pointsPerShipment: PropTypes.string,
  shipmentMinWeightUnits: PropTypes.string,
  shipmentMinWeightValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  t: PropTypes.object.isRequired,
}

export default PointsSection
